<template>
    <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
        <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
            <div class="navbar-start">
                <nav-bar-menu class="has-divider">
                    <div class="is-user-name">
                        <span>{{ user.name }}</span>
                        <span>{{ user.email }}</span>
                    </div>

                    <div slot="dropdown" class="navbar-dropdown">
                        <a @click.prevent="editUser()" class="navbar-item">
                            <b-icon icon="account" custom-size="default"></b-icon>
                            <span>Profil bearbeiten</span>
                        </a>
                        <a @click.prevent="logout" class="navbar-item">
                            <b-icon icon="logout" custom-size="default"></b-icon>
                            <span>Log Out</span>
                        </a>
                    </div>
                </nav-bar-menu>

                <nav-bar-menu>
                    <div class="is-language">
                        <span>{{ currentCountry }}</span>
                        <span>{{ currentLanguage }}</span>
                    </div>

                    <div slot="dropdown" class="navbar-dropdown">
                        <template v-for="(country,index) in $root.countries">
                            <div v-if="hasMinOneActiveLangauge(country)" :key="country.Id">
                                <hr v-if="index > 0" class="navbar-divider">
                                <label class="navbar-item">{{ country.CountryI18ns[0].Name }}</label>
                                <template v-for="(language, lindex) in country.Languages">
                                    <a v-if="language.StateId == 1" @click.prevent="changeLocale(language)" :key="lindex" class="navbar-item is-language">
                                        {{ language.LanguageI18ns[0].Name }}
                                    </a>
                                </template>
                            </div>
                        </template>
                    </div>
                </nav-bar-menu>
            </div>
        </div>
        <div class="navbar-brand is-right">
            <a class="navbar-item navbar-item-menu-toggle is-hidden-touch"
               :class="{'active': isAnyImportRunning}"
               :title="$t('Import languages')"
               v-if="user.roleShortcut === 'admin'"
               @click.prevent="$emit('toggleImportModal')">
                <b-icon icon="database-import" custom-size="default"></b-icon>
            </a>
            <a @click.prevent="menuToggle"
               :title="toggleTooltip"
               class="navbar-item navbar-item-menu-toggle is-hidden-touch">
                <b-icon icon="menu" custom-size="default"></b-icon>
            </a>
            <div class="navbar-item navbar-item-menu-toggle is-hidden-desktop">
                <a href="#/menu/toggle" @click.prevent="menuToggleMobile">
                    <b-icon icon="menu" custom-size="default"></b-icon>
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
import NavBarMenu from '@/components/NavBarMenu'
import {mapState} from 'vuex'

export default {
    name: "NavBar",

    components: {
        NavBarMenu
    },

    props: {
        isAnyImportRunning: {
            type: Boolean,
        },
    },

    data() {
        return {
            user: {
                id: 0,
                name: 'Loading ...',
                email: 'Loading ...',
            },
            currentCountry: 'Loading ...',
            currentLanguage: 'Loading ...',
            isMenuNavBarActive: false,
        }
    },

    computed: {
        toggleTooltip() {
            return (this.isAsideWideActive) ? 'Collapse' : 'Expand'
        },
        ...mapState([
            'isNavBarVisible',
            'isNavBarMenusHoverable',
            'isAsideWideActive'
        ])
    },

    mounted() {
        this.setHtmlClass(this.isNavBarVisible)

        if (this.$root.Storage.get('user') !== null) {
            this.user = this.$root.Storage.get('user')
        }
    },

    updated () {
        this.setSelectedLanguage()
    },

    methods: {
        menuToggle() {
            this.$jbUtils.toggle(null, true)
        },
        menuToggleMobile() {
            this.$jbUtils.toggleMobile()
        },

        setHtmlClass(val) {
        let htmlClass = 'has-navbar-fixed-top'

        if (val)
            this.$jbUtils.htmlAddClass(htmlClass)
        else
            this.$jbUtils.htmlRemoveClass(htmlClass)
        },

        /**
         * Evaluates if the given country contains at least one language that is StateId = 1
         */
        hasMinOneActiveLangauge (country) {
            return country.Languages.find(language => {
                return language.StateId == 1
            }) || false
        },

        /**
         * Logging out the user and redirect to the home page.
         */
        logout() {
            this.$root.logoutUser()
        },

        // eslint-disable-next-line
        changeLocale(language) {
            let count = 0
            this.$root.findChild(this, 'NavBarMenu', menu => {
                if (count++ == 1) {
                    menu.isDropdownActive = false
                }
            })
            this.$root.settings('locale', language.LocaleCode)
            this.setSelectedLanguage()
            this.$root.$children[0].refresh()
        },

        setSelectedLanguage() {
            if (this.$root.computed.countries.length) {
                this.$root.currentLanguage((language, country) => {
                    this.$root.settings('localeId', language.Id)
                    this.currentLanguage = this.$root.translation(language.LanguageI18ns, {}, 'de_AT').Name
                    this.currentCountry = this.$root.translation(country.CountryI18ns, {}, 'de_AT').Name
                })
            }
        },

        editUser() {
            let source = 'source' in this.$route.params ? this.$route.params.source : []
            source.push({
                name: 'users',
                title: 'Benutzer',
            })

            let params = {
                for: 'User',
                id: this.user.id,
                source: source,
            }

            this.$router.push({
                name: 're-details',
                params: params
            })
        },
    },

    watch: {
        isNavBarVisible(newVal) {
            this.setHtmlClass(newVal)
        }
    }
}
</script>
