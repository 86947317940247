/* MDI icons */
import '@mdi/font/css/materialdesignicons.css'
import 'select2-component/dist/select2.css'

/* Vue, App, store & router */
import qs from 'qs'
import Vue from 'vue'
import axios from 'axios'
import Buefy from 'buefy'
import moment from 'moment'
import App from './App.vue'
import store from './store'
import router from './router'
import VTooltip from 'v-tooltip'
import JbUtils from '@/classes/JbUtils'
import Vue2TouchEvents from 'vue2-touch-events'
import AsideMenuListPlain from '@/components/AsideMenuListPlain'
import VueI18n from 'vue-i18n';
import messages from '@/translations/translations.json'
import { Select2 } from 'select2-vue-component'

// Graspointner Componentents (should be available globally)
import BgTable from '@/components/BgTable'
import BgTableCollection from '@/components/BgTableCollection'
import BgTableGroup from '@/components/BgTableGroup'
import BgTableToolBar from '@/components/BgTableToolBar'
import BgColumn from '@/components/BgColumn'
import BgColumnList from '@/components/BgColumnList'
import BgColumnTags from '@/components/BgColumnTags'
import BgColumnFiles from '@/components/BgColumnFiles'
import BgColumnEdited from '@/components/BgColumnEdited'
import BgColumnWrapper from '@/components/BgColumnWrapper'
import BgColumnProperty from '@/components/BgColumnProperty'
import BgColumnProgress from '@/components/BgColumnProgress'
import BgColumnRelation from '@/components/BgColumnRelation'
import BgUpload from '@/components/BgUpload'
import BgForm from '@/components/BgForm'
import BgFormTags from '@/components/BgFormTags'
import BgFormInput from '@/components/BgFormInput'
import BgFormUpload from '@/components/BgFormUpload'
import BgFormSelect from '@/components/BgFormSelect'
import BgFormToggle from '@/components/BgFormToggle'
import BgFormWrapper from '@/components/BgFormWrapper'

import BgTableMedia from '@/views/Tables/Media'
import BgTableGroups from '@/views/Tables/Groups'
import BgTableSystems from '@/views/Tables/Systems'
import BgTableProducts from '@/views/Tables/Products'
import BgTableComponents from '@/views/Tables/Components'
import BgTableCollections from '@/views/Tables/Collections'

import AdminNeos from '@/views/admin/Neos'
import AdminSap from '@/views/admin/Sap'
import AdminMt from '@/views/admin/Mt'

const apiBaseUrl = '/api/';

/**
 * Token 🔐 class
 */
let Token = {
    __token: undefined,
    __validated: undefined,
    __axios_instance: undefined,

    /**
     * Returns the token.
     *
     * @return {string}
     */
    get() {
        if (typeof this.__token === 'undefined') {
            this.__token = Cookie.get('token')
        }

        return this.__token
    },

    /**
     * Changes the token to the given value. (Use this with caution, a warning will be logged.)
     *
     * @param {string} newToken
     */
    set(newToken) {
        this.__token = newToken
        this.__validated = true
        Cookie.set('token', newToken)
    },

    /**
     * Evaluates if the token is valid.
     *
     * @return {boolean}
     */
    isValid() {
        if (typeof this.__validated === 'boolean') {
            return this.__validated
        }

        if (typeof this.__token === 'undefined') {
            this.__token = Cookie.get('token')
        }

        if (typeof this.__axios_instance === 'undefined') {
            this.__axios_instance = axios.create({
                timeout: 1000,
                baseURL: apiBaseUrl,
                headers: {
                    Authorization: 'Bearer ' + this.__token
                }
            })
        }

        return this.__axios_instance.get('validate').then(response => {
            if ('user' in response.data.data) {

                let user = response.data.data.user

                if (!('settings' in user) || (typeof user.settings !== 'object') || user.settings == null || !('Tables' in user.settings)) {
                    user.settings = {
                        Tables: {}
                    }
                }

                Storage.set('user', user)
                vue_$root.findChild(vue_$root, 'home', (child) => {
                    child.buildMenu()
                })
            }

            this.__validated = true
            return true
        }).catch(() => {
            this.__validated = false
            return false
        })
    },

    /**
     * Resets the token object.
     */
    reset() {
        Cookie.remove('token')
        this.__token = undefined
        this.__validated = undefined
        this.__axios_instance = undefined
    },
}

/**
 * Cookie 🍪 class
 */
let Cookie = {
    prefix: 'bg-mt-',

    /**
     * Returns the value for the given key.
     *
     * @param  {string}  key
     * @return {string}
     */
    get(key) {
        key = this.prefix + key + '='

        let value = decodeURIComponent(document.cookie).split(';').find(cookie => {
            return (cookie.trim().indexOf(key) == 0)
        })

        return typeof value === 'string' ? JSON.parse(value.replace(key, '').trim()) : ''
    },

    /**
     * Sets the given key-value pair.
     *
     * @param {string} key
     * @param {string} value
     */
    set(key, value) {
        const livetime = moment().add(1, 'W').format('ddd, DD MMM YYYY HH:mm:ss') + ' GMT'
        document.cookie = this.prefix + key + '=' + JSON.stringify(value) + ';expires=' + livetime + ';path=/'
    },

    /**
     * Removes the given key and its data.
     *
     * @param {string} key
     */
    remove(key) {
        document.cookie = 'bg-mt-' + key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    },
}

/**
 * Local Storage 🗄 Class
 */
let Storage = {
    prefix: 'bg-mt-',

    /**
     * Returns the value for the given key.
     *
     * @param  {string}  key
     * @return {*}
     */
    get(key) {
        return JSON.parse(localStorage.getItem(key))
    },

    /**
     * Sets the given key-value pair.
     *
     * @param {string} key
     * @param {*}      value
     */
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },

    /**
     * Removes the given key and its data.
     *
     * @param {string} key
     */
    remove(key) {
        localStorage.removeItem(key)
    },

    /**
     * Removes the keys by given wildcard
     *
     * @param {string} wildcard
     */
    removeByWildcard(wildcard) {
        let arr = []
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).includes(wildcard)) {
                arr.push(localStorage.key(i))
            }
        }

        for (var i = 0; i < arr.length; i++) {
            localStorage.removeItem(arr[i])
        }
    },
}

// Vue Router 🚦 configuration ...
router.beforeEach(async (to, from, next) => {
    if (await Token.isValid()) {
        if (to.path != '/login') {
            next()
        } else {
            next('/sub-brands')
        }
        return
    }

    if (to.path != '/login') {
        next('/login')
        return
    }

    next()
})

Vue.use(Buefy)
Vue.use(VTooltip)
Vue.use(Vue2TouchEvents)
Vue.use(VueI18n);
Vue.config.productionTip = false

// Global Components
Vue.component('select2', Select2)

// Table / Columns
Vue.component('BgTable', BgTable)
Vue.component('BgTableCollection', BgTableCollection)
Vue.component('BgTableGroup', BgTableGroup)
Vue.component('BgTableToolBar', BgTableToolBar)

Vue.component('BgColumn', BgColumn)
Vue.component('BgColumnList', BgColumnList)
Vue.component('BgColumnTags', BgColumnTags)
Vue.component('BgColumnFiles', BgColumnFiles)
Vue.component('BgColumnEdited', BgColumnEdited)
Vue.component('BgColumnWrapper', BgColumnWrapper)
Vue.component('BgColumnProperty', BgColumnProperty)
Vue.component('BgColumnProgress', BgColumnProgress)
Vue.component('BgColumnRelation', BgColumnRelation)

// Form
Vue.component('BgUpload', BgUpload)

// Form
Vue.component('BgForm', BgForm)
Vue.component('BgFormTags', BgFormTags)
Vue.component('BgFormInput', BgFormInput)
Vue.component('BgFormUpload', BgFormUpload)
Vue.component('BgFormSelect', BgFormSelect)
Vue.component('BgFormToggle', BgFormToggle)
Vue.component('BgFormWrapper', BgFormWrapper)

// Table
Vue.component('BgTableMedia', BgTableMedia)
Vue.component('BgTableGroups', BgTableGroups)
Vue.component('BgTableSystems', BgTableSystems)
Vue.component('BgTableProducts', BgTableProducts)
Vue.component('BgTableComponents', BgTableComponents)
Vue.component('BgTableCollections', BgTableCollections)

// Admin
Vue.component('AdminNeos', AdminNeos)
Vue.component('AdminSap', AdminSap)
Vue.component('AdminMt', AdminMt)

// Theme Specific
Vue.component('AsideMenuListPlain', AsideMenuListPlain)

Vue.filter('truncate', function (text, stop, clamp) {
    if (!text) {
        return ''
    }
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

Vue.filter('sprintf', function (text, vars) {
    if (!text) {
        return ''
    }

    return text.replace(/%\w+%/g, function (all) {
        return vars[all] || all;
    });
})

const $html = document.getElementsByTagName('html')[0]
const jbUtils = new JbUtils($html, store)
Vue.prototype.$jbUtils = jbUtils
if (!store.state.isAsideWideActive) {
    jbUtils.toggle(null, true)
}

/**
 * Uppercase the first letter of the string.
 */
String.prototype.ucfirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

// eslint-disable-next-line
console.log('👋🏼 Hey there, hope you know what you\'re doing.')

const i18n = new VueI18n({
    locale: 'de_AT', // set locale
    fallbackLocale: 'en_GB', // set fallback locale
    messages, // set locale messages
});

/**
 * Vue instance
 */
let vue_$root = new Vue({
    i18n,
    store,
    router,
    render: h => h(App),
    data: {
        computed: {
            // This will be automatically created
            countries: [],
            dataLanguages: [],

            // Restriction Buffer for delayed responses
            countriesRestriction: null,

            // Use the .settings(key[, value]) method instead of a direct object manipulation.
            settings: Storage.get('settings') || {
                locale: 'de_AT',
                localeId: 0,
            },
        },

        // Token 🔐 class
        Token: Token,

        // Cookie 🍪 class
        Cookie: Cookie,

        // Local Storage 🗄 Class
        Storage: Storage,

        // axios
        axiosInstance: null,
        apiBaseUrl: apiBaseUrl,
    },

    mounted() {
        $html.classList.remove('has-spinner-active')
        if (Storage.get('settings') && typeof Storage.get('settings').interfaceLanguage !== 'undefined') {
            this.$i18n.locale = Storage.get('settings').interfaceLanguage
        }
    },

    computed: {
        countries: {
            get: function () {
                if (this.computed.countries.length < 1) {
                    // eslint-disable-next-line
                    this.fetch('country?count=-1').then(response => {
                        // eslint-disable-next-line
                        this.computed.countries = response.Country
                        if (this.computed.countriesRestriction != null) {
                            this.restrictCountries(this.computed.countriesRestriction)
                        }
                    })
                }

                return this.computed.countries
            },
            set: function () {
                throw new Error('🦉 You are not allowed to set countries.')
            }
        },

        dataLanguages: {
            get: function () {
                if (this.computed.dataLanguages.length < 1) {
                    // eslint-disable-next-line
                    this.fetch('language?count=-1&order[column]=Id').then(response => {
                        // eslint-disable-next-line
                        this.computed.dataLanguages = response.Language
                    })
                }

                return this.computed.dataLanguages
            },
            set: function () {
                throw new Error('🦉 You are not allowed to set data languages.')
            }
        }
    },

    methods: {

        /**
         * Removes all cookies and resets the token object.
         */
        logoutUser() {
            // eslint-disable-next-line
            console.log('👋🏼 Bye bye ...')
            this.Storage.remove('user')
            this.Storage.remove('settings')
            this.Token.reset()
            window.location.href = '/'
        },

        apiGet: function (call) {
            console.log('⚠️ Outdated apiGet got called. Use the new .fetch() instead!')
            return this.axios().get(call).then(response => (response.data.data))
        },
        post: function (call, params = {}) {
            return this.axios().post(
                call,
                qs.stringify(params)
            ).then(response => {
                if (typeof response.data.data === 'undefined' || (typeof params.action !== 'undefined' && params.action === 'copyFileI18n')) {
                    return response.data
                }
                return response.data.data
            })
        },
        uri: function (call, parameters) {
            return this.axios().getUr(call, qs.stringify(parameters)).then(response => (response.data.data))
        },
        getParentByName: function (instance, componentName) {
            let component = null
            let parent = instance.$parent
            while (parent && !component) {
                if (parent.$options.name === componentName) {
                    component = parent
                }
                parent = parent.$parent
            }
            return component
        },

        /**
         * Sets or return the value for a key.
         *
         * @param {string} key
         * @param {*} value
         */
        settings(key, value) {
            if (typeof value === 'undefined') {
                return this.computed.settings[key]
            } else if (value === null) {
                delete this.computed.settings[key]
            }

            this.computed.settings[key] = value

            this.Storage.set('settings', this.computed.settings)
        },

        /**
         * Finds and returns a specific parent by its name
         */
        findParent(component, parentName) {
            return (typeof component !== 'undefined'
                ? (component.$options.name === parentName
                    ? component
                    : this.findParent(component.$parent, parentName))
                : undefined)
        },

        /**
         * Returns a valid axios instance.
         *
         * @return {axios}
         */
        axios() {
            let auth = 'Bearer ' + this.Token.get()

            if (this.axiosInstance == null) {
                this.axiosInstance = axios.create({
                    timeout: 30000,
                    baseURL: apiBaseUrl,
                    headers: {
                        Authorization: auth
                    }
                })
            }

            if (this.axiosInstance.defaults.headers.Authorization !== auth) {
                this.axiosInstance.defaults.headers.Authorization = auth
            }

            return this.axiosInstance
        },

        /**
         * Requests the API with a GET.
         *
         * @param  {string}  call
         * @param  {object}  params
         * @return {object}
         */
        fetch: function (call, params = {}) {
            return this.axios().get(call, {params: params})
                .then(response => {
                    if (typeof response.data.data === 'undefined' || (typeof params.action !== 'undefined' && params.action === 'copyFileI18nCheck')) {
                        return response.data
                    }
                    return response.data.data
                })
                .catch(error => {
                    if ('response' in error && 'status' in error.response) {
                        if (error.response.status == 403) {
                            this.findChild(this, 'home', (child) => {
                                child.notify(this.$t('Not enough permissions.'), 'danger')
                            })
                        }
                    }
                })
        },

        /**
         * Requests the API with a DELETE.
         *
         * @param  {string}  call
         * @param  {object}  params
         * @return {object}
         */
        delete: function (call, params) {
            return this.axios().delete(call, {params: params}).then(response => response.data.data)
        },

        /**
         * Search the required property within the hole property array.
         *
         * @param  {array}   properties All collection properties.
         * @param  {string}  property Property that should be returned.
         * @return {object}
         */
        findProperty: function (properties, property) {
            if (!Array.isArray(properties)) {
                throw new Error('🦉 Properties for "' + property + '" is no array.')
            }

            return properties.find(group => {
                return group.Property.PropertyType.PropertyKey == property
            })
        },

        /**
         * Returns a specific value of the given property.
         *
         * @param  {object}  property Full property object.
         * @param  {string}  key Key for the value.
         * @return {string}
         */
        getPropertyValue: function (property, key) {
            if (typeof property !== 'object' || !('Property' in property)) {
                throw new Error('🦉 No valid property object.')
            }

            let found = property.Property.PropertyValues.find(value => {
                return value.VKey == key
            })

            return (typeof found !== 'undefined' && 'VValue' in found) ? found.VValue : undefined;
        },

        /**
         * Returns the folder object for the given shortcut.
         *
         * @param  {object}  collection Full collection opject.
         * @param  {string}  shortcut Fodler shortcut.
         * @return {object}
         */
        findFolder: function (collection, shortcut) {
            if (typeof collection !== 'object' || !('CollectionType' in collection)) {
                throw new Error('🦉 No valid collection object.')
            }

            return collection.CollectionType.Folders.find(currentFolder => {
                return currentFolder.Shortcut == shortcut
            })
        },

        /**
         * Finds the translation from all translations.
         *
         * @param {array}            translations Array with all translations.
         * @param {string|undefined} fallback (option) Fallback string that should be returned
         * @param {string|undefined} localeCode (option) Forced language
         */
        translation: function (translations, fallback, localeCode) {
            if (!Array.isArray(translations) || translations.length < 1 || !('Locale' in translations[0])) {
                if (typeof fallback === 'undefined') {
                    return fallback
                }
                throw new Error('🦉 No valid translations object.')
            }

            let translation = translations.find(currentTranslation => {
                return currentTranslation.Locale == (localeCode ? localeCode : this.settings('locale'))
            })

            return (translation !== undefined) ? translation : fallback || {}
        },


        /**
         * Finds an specific child within the given Vue-instance.
         *
         * @param  {object}    instance
         * @param  {string}    name
         * @param  {function}  callback
         */
        findChild: function (instance, name, callback) {
            for (let child in instance.$children) {
                if (instance.$children[child].$options.name == name || name == '*' || (name.includes('*') && instance.$children[child].$options.name.includes(name.replace('*', '')))) {
                    callback(instance.$children[child])
                }
            }
        },

        /**
         * Returns a stack (array) of all parents.
         * Optional: All parents until the stop.
         *
         * @param  {object}  instance
         * @param  {string}  stop
         * @return {array|undefined}
         */
        parentsStack: function (instance, stop) {
            stop = stop || null

            let parents = []

            if (instance.$options.name != 'Root' && instance.$options.name != stop) {
                parents = this.parentsStack(instance.$parent, stop)
            }

            parents.push(instance)

            return parents
        },

        /**
         *  Replaces all Placeholders with the values from an object.
         *
         * @param  {string}  string
         * @param  {object}  obj
         * @return {string}
         */
        stringPlaceholders(string, obj) {
            string = string.split('${')
            for (let pos in string) {
                if (pos != 0) {
                    let scraps = string[pos].split('}')
                    let key = scraps.shift()
                    scraps = scraps.join('}')

                    if (key in obj) {
                        scraps += obj[key] + scraps
                    }

                    string[pos] = scraps
                }
            }
            return string.join('')
        },

        /**
         * Iterates over each language until there is an return false.
         *
         * @param {function} callback
         */
        eachLanguage(callback) {
            if (this.countries.length > 1) {
                let exit = false
                for (let countryKey in this.countries) {
                    for (let languageKey in this.countries[countryKey].Languages) {
                        exit = callback(this.countries[countryKey].Languages[languageKey], this.countries[countryKey], languageKey, countryKey) === false

                        if (exit) {
                            break
                        }
                    }

                    if (exit) {
                        break
                    }
                }
            }
        },

        /**
         * Executes the callback for the currently selected language and country.
         *
         * @param {function} callback
         * @return {object}
         */
        currentLanguage(callback) {
            this.eachLanguage((language, country) => {
                if (language.LocaleCode == this.settings('locale')) {
                    callback(language, country)
                    return false
                }
            })
        },


        /**
         *
         */
        restrictCountries(translations) {
            if (this.computed.countries.length >= 1) {
                if (!Array.isArray(translations) || translations.length < 1 || !('Locale' in translations[0])) {
                    if (translations !== '*') {
                        throw new Error('🦉 No valid translations object.')
                    }
                }

                let supported = '*'

                if (translations !== '*') {
                    supported = []
                    translations.forEach(language => {
                        supported.push(language.Locale)
                    })
                }

                this.computed.countriesRestriction = null

                let debug = {
                    allow: [],
                    disallow: [],
                }

                this.eachLanguage((language, country, languageKey, countryKey) => {
                    if (supported === '*' || supported.includes(language.LocaleCode)) {
                        if (this.computed.countries[countryKey].Languages[languageKey].StateId == 999) {
                            this.computed.countries[countryKey].Languages[languageKey].StateId = 1
                        }

                        debug.allow.push(language.LocaleCode)

                        return true
                    }

                    debug.disallow.push(language.LocaleCode)

                    if (this.computed.countries[countryKey].Languages[languageKey].StateId == 1) {
                        this.computed.countries[countryKey].Languages[languageKey].StateId = 999
                    }
                })

                // console.log('🏳️‍🌈 Language restriction allowed ' + debug.allow.length + ' language(s) and hides ' + debug.disallow.length + '.')
            } else {
                console.log('🏳️‍🌈 Language restriction got buffered.')
                this.computed.countriesRestriction = translations
            }
        },

        /**
         * Evaluates whether the master object contains the key-value pairs of the compare object.
         *
         * @param  {object}  master
         * @param  {object}  compare
         * @return {boolean}
         */
        objectContains(master, compare) {
            if (typeof master !== 'object' || typeof compare !== 'object') {
                throw new Error('🦉 \'master\' and/or \'compare\' should be objects.')
            }

            for (let key in compare) {
                if (key in master) {
                    if (!this.compareValues(master[key], compare[key])) {
                        return false
                    }
                } else {
                    return false
                }
            }

            return true
        },

        /**
         * Compares two values
         *
         * @param  {*}  master
         * @param  {*}  compare
         * @return {boolean}
         */
        compareValues(master, compare) {
            if (typeof master == 'object') {
                if (Array.isArray(master) && Array.isArray(compare)) {
                    if (master.length !== compare.length) {
                        return false
                    }
                } else if (!Array.isArray(master) && !Array.isArray(compare)) {
                    if (!this.objectContains(master, compare)) {
                        return false
                    }
                } else {
                    return false
                }
            } else if (master != compare) {
                return false
            }

            return true
        },

        /**
         * Returns whether the given dataset is an image.
         *
         * @param  {number}  size
         * @return {string}
         */
        fileSize(size, iteration) {
            iteration = iteration || 0

            let sizeTable = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'] // Aktuell mögliche größen, auch wenn TB und PB unrealistisch 😅

            if (size >= 100) {
                return this.fileSize((size / 1000), (iteration + 1))
            } else {
                return (Number.parseFloat(size).toFixed(1)) + sizeTable[iteration]
            }
        },

        isReadAllowed(object) {
            return this.hasPermission(object, 100)
        },

        hasPermission(object, operation) {
            let user = this.$root.Storage.get('user')
            if (user !== null && user.permission !== undefined) {
                if (user.permission[object] < operation) {
                    return false
                }
            }

            return true
        },

        childObjectHasPropertyValue(objectCollection, $property, propertyValue) {
            if (typeof objectCollection !== 'undefined') {
                for (let index in objectCollection) {
                    if (objectCollection[index][$property] === propertyValue) {
                        return false
                    }
                }
            }
            return true
        },

        sPrintF(text, vars) {
            if (!text) {
                return ''
            }

            return text.replace(/%\w+%/g, function (all) {
                return vars[all] || all
            });
        },
    }
}).$mount('#app')
