<script>
    import Filters from '../filters'

    export default {
        mixins: [Filters],
        data() {
            return {
                commandProcessing: {
                    uploads: false,
                    db: false,
                    sap: false,
                    neos: false,
                    persistent: false,
                },
                errorMessage: '',
                successMessage: '',
                currentTab: 'mt',
                copySourceLanguage: '',
                copyLanguagesSelection: '',
                copyFolderSelection: '',
                availableLanguages: [],
                folders: [],
                uploadsStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                dbStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                sapStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                neosStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                persistentStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                massActionStatus: {
                    start: 0,
                    processing: 0,
                    finished: 0,
                },
                cacheStructure: {},
                cacheFunctions: [],
                rebuildCachesFiles: [],
                mtCacheLabelMapping: {},
                copyLanguages: [],
                copyFolders: [],
                copyUpdate: null,
                copyUpdateCompleted: null,
                disableCopyButton: true,
            }
        },

        mounted() {
            this.$store.commit('mainPage', {
                current: this.$t('Admin'),
                stack: [{
                    'path': '/admin',
                    'title': this.$t('Admin'),
                }]
            })
            if (this.isAdmin()) {
                this.fetchData()
                setInterval(this.fetchData, 10000)
            }
            this.$root.fetch('folder', {count: -1}).then(response => {
                this.folders = response.Folder
            })
        },

        methods: {
            isAdmin() {
                let user = this.$root.Storage.get('user')

                return user && user.roleShortcut === 'admin'
            },
            fetchData() {
                this.$root.fetch('admin', {
                    action: 'getStatus'
                }).then((response) => {
                    if (Array.isArray(response.importStatus)) {
                        this.availableLanguages = response.importStatus
                        this.cacheStructure = response.cacheStructure
                        this.cacheFunctions = response.cacheFunctions
                        this.mtCacheLabelMapping = response.mtCacheLabelMapping
                        this.rebuildCachesFiles = response.rebuildCachesFiles
                        this.uploadsStatus = response.uploadsStatus
                        this.dbStatus = response.dbStatus
                        this.sapStatus = response.sapStatus
                        this.neosStatus = response.neosStatus
                        this.persistentStatus = response.persistentStatus
                        this.massActionStatus = response.massactionStatus
                    } else {
                        this.errorMessage = response.data
                        setTimeout(this.resetMessage, 4000)
                    }
                })
            },
            resetMessage() {
                this.errorMessage = ''
                this.successMessage = ''
            },
            executeCommand(command) {
                this.resetMessage()
                if (confirm(this.$t('Are you sure you want to do this action?'))) {
                    this.commandProcessing[command] = true
                    this.$root.fetch('command', {
                        command: command,
                    }).then((response) => {
                        this.commandProcessing[command] = false
                        if (response) {
                            this.successMessage = response
                        } else {
                            this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                        }
                        this.fetchData()
                        setTimeout(this.resetMessage, 4000)
                    })
                }
            },
            changeTab(tabName) {
                this.currentTab = tabName
            },
            view(tab) {
                return this.currentTab === tab
            },
            updateCopyData() {
                this.$root.fetch('file', {
                    action: 'copyFileI18nCheck',
                    sourceLanguage: this.copySourceLanguage,
                    targetLanguages: (this.copyLanguagesSelection === 'all' ? '*' : this.copyLanguages.join(',')),
                    sourceFolders: (this.copyFolderSelection === 'all' ? '*' : this.copyFolders.join(',')),
                })
                    .then((response) => {
                        if (response.success) {
                            this.disableCopyButton = false
                            this.copyUpdate = response.data
                        } else {
                            this.disableCopyButton = true
                            this.copyUpdate = null
                            this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                            console.log(response.message)
                        }
                    })
            },
            performCopyData() {
                if (confirm(this.$t('Do you want to start copy action?'))) {
                    this.$root.post('file', {
                        'action': 'copyFileI18n',
                        'sourceLanguage': this.copySourceLanguage,
                        'targetLanguages': (this.copyLanguagesSelection === 'all' ? '*' : this.copyLanguages.join(',')),
                        'sourceFolders': (this.copyFolderSelection === 'all' ? '*' : this.copyFolders.join(',')),
                    })
                        .then((response) => {
                            this.copyUpdate = null
                            if (response.success) {
                                this.copyUpdateCompleted = response.data
                                const _that = this
                                setTimeout(function () {
                                    _that.copyUpdateCompleted = null
                                }, 10000)
                            } else {
                                this.disableCopyButton = true
                                this.copyUpdateCompleted = null
                                this.errorMessage = this.$t('Something went wrong. Please contact administrator.')
                                console.log(response.message)
                            }
                            this.copySourceLanguage = ''
                            this.copyLanguagesSelection = ''
                            this.copyFolderSelection = ''
                            this.fetchData()
                        })
                }
            }
        },

        computed: {
            disableCopyFileI18nButton() {
                return !this.copySourceLanguage || (!this.copyLanguages.length && !this.copyLanguagesSelection) || (!this.copyFolders.length && !this.copyFolderSelection);
            },
        },

        watch: {
            copyLanguagesSelection(newValue) {
                this.copyLanguages = []
                if (newValue) {
                    this.availableLanguages.forEach(language => {
                        this.copyLanguages.push(language.locale)
                    })
                }
            },
            copyFolderSelection(newValue) {
                this.copyFolders = []
                if (newValue) {
                    this.folders.forEach(folder => {
                        this.copyFolders.push(folder.Id)
                    })
                }
            },
            copyFolders(newValue) {
                if (!this.disableCopyFileI18nButton && newValue.length) {
                    this.updateCopyData()
                }
            },
            copyLanguages(newValue) {
                if (!this.disableCopyFileI18nButton && newValue.length) {
                    this.updateCopyData()
                }
            },
        }
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <div class="b-tabs" v-if="isAdmin">
                <nav class="tabs is-boxed">
                    <ul>
                        <li :class="{ 'is-active': currentTab === 'mt' }">
                            <a @click="changeTab('mt')"><span>{{ $t('Mapping Tool Project') }}</span></a>
                        </li>
                        <li :class="{ 'is-active': currentTab === 'neos' }">
                            <a @click="changeTab('neos')"><span>{{ $t('NEOS Project') }}</span></a>
                        </li>
                        <li :class="{ 'is-active': currentTab === 'sap' }">
                            <a @click="changeTab('sap')"><span>SAP</span></a>
                        </li>
                        <li :class="{ 'is-active': currentTab === 'mt-file-i18n' }">
                            <a @click="changeTab('mt-file-i18n')"><span>{{ $t('Multi actions') }}</span></a>
                        </li>
                    </ul>
                </nav>

                <section class="tab-content card is-card-table is-card-detail">
                    <div class="tab-item">
                        <article :class="{ 'message': true, 'is-primary': successMessage, 'is-danger': errorMessage }"
                                 v-if="errorMessage || successMessage">
                            <div class="message-body">
                                {{ errorMessage }}{{ successMessage }}
                            </div>
                        </article>

                        <template v-if="view('neos')">
                            <admin-neos :neos-status="neosStatus"
                                        :sap-status="sapStatus"
                                        :persistent-status="persistentStatus"
                                        :uploads-status="uploadsStatus"
                                        :db-status="dbStatus"
                                        :command-processing="commandProcessing"
                                        :cache-structure="cacheStructure"
                                        :cache-functions="cacheFunctions"
                                        :cache-labels="mtCacheLabelMapping"
                                        :rebuild-cache-files="rebuildCachesFiles"
                                        @execute="executeCommand" />
                        </template>

                        <template v-if="view('sap')">
                            <admin-sap :available-languages="availableLanguages" />
                        </template>

                        <template v-if="view('mt')">
                            <admin-mt :command-processing="commandProcessing"
                                      :neos-status="neosStatus"
                                      :persistent-status="persistentStatus"
                                      :uploads-status="uploadsStatus"
                                      :db-status="dbStatus"
                                      :sap-status="sapStatus"
                                      @execute="executeCommand" />
                        </template>

                        <template v-if="view('mt-file-i18n')">
                            <h4 class="subtitle is-4">{{ $t('Check and create language content for files') }}</h4>
                            <div class="columns">
                                <div class="column is-6">
                                    <bg-form-select
                                        :label="'1. ' + $t('Source: language version')"
                                        name="--random--1"
                                        :addColumnClass="false"
                                        placeholder="Bitte auswählen ..."
                                        :size="6"
                                        aria-required="true"
                                        :options="[...[{ Id: 0, CountryI18ns: [{ Id: 0, Locale: 'de_AT', Name: 'International' }], Languages: [{ Id: 0, CountryId: 0, LocaleCode: 'zz_ZZ', LanguageI18ns: [{ Id: 0, Locale: 'de_AT', Name: 'Ohne Sprache (alt)' }] }] }], ...$root.countries]"
                                        @sync="value => copySourceLanguage = value"
                                        :option-group="option => option.Languages"
                                        :option-group-label="option => $root.translation(option.CountryI18ns, null, 'de_AT').Name"
                                        :option-id="option => option.LocaleCode"
                                        :option-label="option => $root.translation(option.LanguageI18ns, null, 'de_AT').Name + ' (' + option.LocaleCode + ')'"/>

                                    <b-field v-if="folders" :addons="false" :label="'2. ' + $t('Source: folders')">
                                        <div class="control is-clearfix">
                                            <b-checkbox native-value="all"
                                                        :disabled="!!massActionStatus.processing"
                                                        v-model="copyFolderSelection"
                                                        aria-checked="true">
                                                {{ $t('All folders') }}
                                            </b-checkbox>
                                        </div>
                                        <div class="control is-clearfix" style="height: 350px;overflow-y: auto;">
                                            <b-checkbox :native-value="folder.Id"
                                                        v-model="copyFolders"
                                                        :disabled="!!massActionStatus.processing"
                                                        style="width:100%;"
                                                        v-for="(folder, key) in folders"
                                                        :key="key">
                                                {{ $root.translation(folder.FolderI18ns, {}, $root.Storage.get('settings').interfaceLanguage).Name }} | {{ folder.Shortcut }} | {{ folder.CollectionType.PhpObject }}
                                            </b-checkbox>
                                        </div>
                                    </b-field>
                                </div>
                                <div class="column is-6">
                                    <b-field :addons="false" :label="'3. ' + $t('Target: language versions')">
                                        <div class="control is-clearfix">
                                            <b-checkbox native-value="all"
                                                        :disabled="!!massActionStatus.processing"
                                                        v-model="copyLanguagesSelection"
                                                        aria-checked="true">
                                                {{ $t('All languages') }}
                                            </b-checkbox>
                                        </div>
                                        <div class="control is-clearfix">
                                            <b-checkbox :native-value="availableLanguage.locale"
                                                        v-model="copyLanguages"
                                                        :disabled="!!massActionStatus.processing"
                                                        v-for="(availableLanguage, key) in availableLanguages"
                                                        :key="key">
                                                {{ availableLanguage.locale }}&nbsp;&nbsp;
                                            </b-checkbox>
                                        </div>
                                    </b-field>

                                    <div v-if="copyUpdate">
                                        <p>{{ $t('This amount of translation will be created on submit') }}:</p>
                                        <ul>
                                            <li v-for="(item, locale) in copyUpdate" :key="locale">
                                                {{ locale }}: {{ item }}
                                            </li>
                                        </ul>
                                        <br>
                                    </div>

                                    <button @click="performCopyData()"
                                            class="button is-primary"
                                            :disabled="disableCopyButton || !!massActionStatus.processing">
                                        {{ $t('Perform') }}
                                    </button>

                                    <div v-if="copyUpdateCompleted">
                                        <br>
                                        <p>{{ $t('Successfully created') }}:</p>
                                        <ul>
                                            <li v-for="(item, locale) in copyUpdateCompleted" :key="locale">
                                                {{ locale }}: {{ item }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div v-if="massActionStatus.processing">
                                        <br>
                                        <p>{{ $t('Processing since') }}: {{ massActionStatus.processing | dateUnix }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </section>
            </div>

            <template v-if="!isAdmin()">
                {{ $t('You have not enough permissions to see this area') }}
            </template>
        </div>
    </section>
</template>
