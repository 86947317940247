<script>
import Filters from '../../filters'

export default {
    props: {
        cacheStructure: {
            type: Object,
        },
        cacheLabels: {
            type: Object,
        },
        cacheFunctions: {
            type: Array,
        },
        rebuildCacheFiles: {
            type: Array,
        },
        neosStatus: {
            type: Object,
        },
        uploadsStatus: {
            type: Object,
        },
        dbStatus: {
            type: Object,
        },
        sapStatus: {
            type: Object,
        },
        persistentStatus: {
            type: Object,
        },
        commandProcessing: {
            type: Object,
        },
    },
    mixins: [Filters],
    data() {
        return {
            cacheSelectedLanguages: [],
            cacheSelectedTypes: [],
            cacheSelectedSubbrand: [],
            cacheSelectedProduct: [],
            cacheSelectedNominalSize: [],
            cacheSelectedFunction: [],
            cacheTypeTranslations: {
                'group': this.$t('Sub-brand'),
                'application_area': this.$t('Application area'),
                'load_class': this.$t('Load class'),
                'product': this.$t('Product'),
            },
            processing: {
                neos: false,
                redis: false,
                sqlite: false,
                reSqlite: false,
                tradosImport: false,
                pageStructure: false,
            },
        }
    },
    computed: {
        getCacheLanguages() {
            let data = []
            for (let language in this.cacheStructure) {
                data.push({
                    value: language,
                    label: language,
                })
            }

            return data
        },
        getCacheTypes() {
            let data = []
            let saved = []
            for (let language in this.cacheStructure) {
                const cacheTypes = this.cacheStructure[language]
                for (let cacheType in cacheTypes) {
                    if (saved.indexOf(cacheType) < 0) {
                        data.push({
                            value: cacheType,
                            label: this.cacheTypeTranslations[cacheType],
                        })
                        saved.push(cacheType)
                    }
                }
            }

            return data
        },
        getCacheSubbrands() {
            let data = []
            let saved = []
            for (let language in this.cacheStructure) {
                const cacheTypes = this.cacheStructure[language]
                for (let cacheType in cacheTypes) {
                    if (cacheType !== 'group') {
                        continue
                    }
                    const ids = cacheTypes[cacheType]
                    for (let id in ids) {
                        let finalIds = [id]
                        if (id.indexOf('-') > -1) {
                            finalIds = id.split('-')
                        }
                        for (let finalId of finalIds) {
                            if (saved.indexOf(finalId) < 0) {
                                const label = typeof this.cacheLabels['group'][finalId] !== 'undefined' ? this.cacheLabels['group'][finalId] : finalId
                                data.push({
                                    value: finalId,
                                    label: label,
                                })
                                saved.push(finalId)
                            }
                        }
                    }
                }
            }

            return data
        },
        getCacheProducts() {
            let data = []
            let saved = []
            for (let language in this.cacheStructure) {
                const cacheTypes = this.cacheStructure[language]
                for (let cacheType in cacheTypes) {
                    if (cacheType !== 'product') {
                        continue
                    }
                    const ids = cacheTypes[cacheType]
                    for (let id in ids) {
                        if (saved.indexOf(id) < 0) {
                            const label = typeof this.cacheLabels['product'][id] !== 'undefined' ? this.cacheLabels['product'][id] : id
                            data.push({
                                value: id,
                                label: label,
                            })
                            saved.push(id)
                        }
                    }
                }
            }

            return data
        },
        getCacheNominalSizes() {
            let data = []
            let saved = []
            for (let language in this.cacheStructure) {
                const cacheTypes = this.cacheStructure[language]
                for (let cacheType in cacheTypes) {
                    if (cacheType !== 'product') {
                        continue
                    }
                    const ids = cacheTypes[cacheType]
                    for (let id in ids) {
                        if (this.cacheSelectedProduct.length && this.cacheSelectedProduct.indexOf(id) < 0) {
                            continue
                        }
                        const nominalSizes = ids[id]
                        for (let nominalSize in nominalSizes) {
                            if (saved.indexOf(nominalSize) < 0) {
                                const label = typeof this.cacheLabels['nominalSize'][nominalSize] !== 'undefined' ? this.cacheLabels['nominalSize'][nominalSize] : nominalSize
                                data.push({
                                    value: nominalSize,
                                    label: label,
                                })
                                saved.push(nominalSize)
                            }
                        }
                    }
                }
            }

            return data
        },
        getCacheFunctions() {
            let data = []
            for (let functionName of this.cacheFunctions) {
                data.push({
                    value: functionName,
                    label: functionName,
                })
            }

            return data
        },
    },
    methods: {
        onCacheLanguageSelected: function (value) {
            this.cacheSelectedLanguages = value
        },
        onCacheTypeSelected: function (value) {
            this.cacheSelectedTypes = value
            if (value.length && value.indexOf('group') < 0) {
                this.cacheSelectedSubbrand = []
            } else if (value.length && value.indexOf('product') < 0) {
                this.cacheSelectedProduct = []
                this.cacheSelectedNominalSize = []
            }
        },
        onCacheSubbrandSelected: function (value) {
            this.cacheSelectedSubbrand = value
        },
        onCacheProductSelected: function (value) {
            this.cacheSelectedProduct = value
        },
        onCacheNominalSizeSelected: function (value) {
            this.cacheSelectedNominalSize = value
        },
        onCacheFunctionSelected: function (value) {
            this.cacheSelectedFunction = value
        },
        getCacheSendParams: function () {
            return {
                languages: this.cacheSelectedLanguages,
                types: this.cacheSelectedTypes,
                groups: this.cacheSelectedSubbrand,
                products: this.cacheSelectedProduct,
                nominalSizes: this.cacheSelectedNominalSize,
                functions: this.cacheSelectedFunction,
            }
        },
        executeAction(action, type, params = {}) {
            this.$parent.resetMessage()
            if (confirm(this.$t('Are you sure you want to do this action?'))) {
                this.processing[type] = true
                this.$root.post('admin', {
                    'action': action,
                    'type': type,
                    'params': params,
                }).then((response) => {
                    this.processing[type] = false
                    if (response.cacheStatus === 'success') {
                        this.$parent.successMessage = response.cacheMessage
                    } else {
                        this.$parent.errorMessage = response.cacheMessage
                    }
                    this.$parent.fetchData()
                    setTimeout(this.$parent.resetMessage, 4000)
                })
            }
        },
        getPlatform() {
            let stage = process.env.NODE_ENV
            if (stage === 'production' && window.location.hostname !== 'data.bg-graspointner.com') {
                stage = 'staging'
            }

            return stage;
        },
        isAnyStatusActive() {
            return !!(this.sapStatus.start || this.sapStatus.processing || this.uploadsStatus.start ||
                this.uploadsStatus.processing || this.dbStatus.start || this.dbStatus.processing ||
                this.neosStatus.start || this.neosStatus.processing || this.persistentStatus.start ||
                this.persistentStatus.processing)
        },
    },
}
</script>

<template>
    <div class="columns">
        <div class="column is-6">
            <h4 class="subtitle is-4">{{ $t('Clear cache of Neos web project') }}</h4>
            <button @click="executeAction('clearCache', 'neos')" class="button is-primary" :disabled="processing.neos">{{ $t('Perform') }}</button>

            <hr>

            <h4 class="subtitle is-4">{{ $t('Clear REDIS cache of Neos web project') }}</h4>
            <button @click="executeAction('clearCache', 'redis')" class="button is-primary" :disabled="processing.redis">{{ $t('Perform') }}</button>

            <hr>

            <h4 class="subtitle is-4">{{ $t('Sqlite cache of Neos web project') }}</h4>
            <div class="level">
                <div class="level-left level-left--start">
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Languages') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select" :data="getCacheLanguages" :value="cacheSelectedLanguages" @update="onCacheLanguageSelected" :multiple="true"/>
                        </div>
                    </div>
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Cache types') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select" :data="getCacheTypes" :value="cacheSelectedTypes" @update="onCacheTypeSelected" :multiple="true"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="level">
                <div class="level-left level-left--start">
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Sub-brands') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select"
                                     :data="getCacheSubbrands"
                                     :value="cacheSelectedSubbrand"
                                     @update="onCacheSubbrandSelected"
                                     :disabled="cacheSelectedTypes.length && cacheSelectedTypes.indexOf('group') < 0"
                                     :multiple="true"/>
                        </div>
                    </div>
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Products') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select"
                                     :data="getCacheProducts"
                                     :value="cacheSelectedProduct"
                                     @update="onCacheProductSelected"
                                     :disabled="cacheSelectedTypes.length && cacheSelectedTypes.indexOf('product') < 0"
                                     :multiple="true"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="level">
                <div class="level-left level-left--start">
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Nominal sizes') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select"
                                     :data="getCacheNominalSizes"
                                     :value="cacheSelectedNominalSize"
                                     @update="onCacheNominalSizeSelected"
                                     :disabled="cacheSelectedTypes.length && cacheSelectedTypes.indexOf('product') < 0"
                                     :multiple="true"/>
                        </div>
                    </div>
                    <div class="level-item level-item--columned">
                        <label>{{ $t('Functions') }}</label>
                        <div class="select is-multiple">
                            <select2 class="collection-select"
                                     :data="getCacheFunctions"
                                     :value="cacheSelectedFunction"
                                     @update="onCacheFunctionSelected"
                                     :multiple="true"/>
                        </div>
                    </div>
                </div>
            </div>

            <p v-if="rebuildCacheFiles.length">{{ $t('Currently existing cache rebuild processes') }}:</p>
            <ul v-if="rebuildCacheFiles.length" class="is-list is-list--disc">
                <li v-for="(item, index) in rebuildCacheFiles" :key="index">
                    {{ (index + 1) }}. {{ $t('Exists since') }} {{ item.readableDate }}
                    <template v-if="item.processing">({{ $t('Already started') }})</template>
                    <template v-if="!item.processing">({{ $t('Not started') }})</template>
                </li>
            </ul>

            <button @click="executeAction('clearCache', 'sqlite', getCacheSendParams())"
                    class="button is-primary"
                    :disabled="processing.sqlite">
                {{ $t('Empty') }}
            </button>&nbsp;
            <button @click="executeAction('clearCache', 'reSqlite', getCacheSendParams())"
                    class="button is-primary"
                    :disabled="processing.sqlite">
                {{ $t('Empty and rebuild') }}
            </button>

            <hr>

            <h4 class="subtitle is-4">{{ $t('Start TRADOS import') }}</h4>
            <button @click="executeAction('tradosImport', 'tradosImport')" class="button is-primary" :disabled="processing.tradosImport">{{ $t('Start') }}</button>

            <hr>

            <h4 class="subtitle is-4">{{ $t('Update product page structure from Mapping Tool') }}</h4>
            <button @click="executeAction('updatePageStructure', 'pageStructure')" class="button is-primary" :disabled="processing.pageStructure">{{ $t('Start') }}</button>
        </div>

        <div class="column is-6" v-if="getPlatform() && getPlatform() !== 'production'">
            <h4 class="subtitle is-4">{{ $t('Copy database from live system') }}</h4>
            <ul class="is-list is-list--disc">
                <li v-if="neosStatus.start">{{ $t('Started at') }}: {{ neosStatus.start | dateUnix }}</li>
                <li v-if="neosStatus.processing">{{ $t('Processing since') }}: {{ neosStatus.processing | dateUnix }}</li>
                <li v-if="neosStatus.finished">{{ $t('Last finished at') }}: {{ neosStatus.finished | dateUnix }}</li>
            </ul>
            <button @click="$emit('execute', 'neos')" class="button is-primary" :disabled="commandProcessing.neos || isAnyStatusActive()">
                <span>{{ $t('Perform') }}</span>
            </button>
            <hr>

            <h4 class="subtitle is-4">{{ $t('Copy media & documents from live system') }}</h4>
            <ul class="is-list is-list--disc">
                <li v-if="persistentStatus.start">{{ $t('Started at') }}: {{ persistentStatus.start | dateUnix }}</li>
                <li v-if="persistentStatus.processing">{{ $t('Processing since') }}: {{ persistentStatus.processing | dateUnix }}</li>
                <li v-if="persistentStatus.finished">{{ $t('Last finished at') }}: {{ persistentStatus.finished | dateUnix }}</li>
            </ul>
            <button @click="$emit('execute', 'persistent')" class="button is-primary" :disabled="commandProcessing.persistent || isAnyStatusActive()">
                <span>{{ $t('Perform') }}</span>
            </button>
        </div>
    </div>
</template>
