<script>
import Filters from '../filters'
import Helpers from '../helpers';

export default {
    props: {
        availableLanguages: {
            type: Array,
        },
        isAnyImportRunning: {
            type: Boolean,
        },
        userEmail: {
            type: String,
        },
    },
    mixins: [Filters, Helpers],
    data() {
        return {
            expertMode: false,
            languagesSelection: '',
            languages: [],
            importOptions: {
                sap: '',
                publish: '',
                products: '',
                sqlite: '',
                reSqlite: '',
                email: '',
            },
        }
    },
    methods: {
        importData() {
            let isValid = true
            if (this.languagesSelection === 'selection' && !this.languages.length) {
                alert(this.$t('Please select at least one language.'))
                isValid = false
            }

            if (isValid && confirm(this.$t('Are you sure you want to do this action?'))) {
                this.$root.post('admin', {
                    action: 'import',
                    languages: this.languages,
                    params: this.importOptions,
                }).then(() => {
                    this.importOptions.sap = ''
                    this.importOptions.publish = ''
                    this.importOptions.products = ''
                    this.importOptions.sqlite = ''
                    this.importOptions.reSqlite = ''
                    this.importOptions.email = ''
                    this.languages = []
                    this.languagesSelection = ''
                    this.$emit('importStarted')
                })
            }
        },
    },
    watch: {
        expertMode(newVal) {
            if (!newVal) {
                this.importOptions.sap = ''
                this.importOptions.publish = ''
                this.importOptions.products = ''
                this.importOptions.sqlite = ''
                this.importOptions.reSqlite = ''
            }
        }
    }
}
</script>

<template>
    <div class="card is-card-table">
        <div class="card-content">
            <div class="columns bg-upload__grid">
                <div class="column">
                    <div class="level">
                        <div class="level-left">
                            <p class="is-size-5">
                                <strong>{{ $t('Import data from SAP') }}</strong>
                            </p>
                        </div>
                        <div class="level-right">
                            <div class="field">
                                <b-switch v-model="expertMode" type="is-success" size="is-medium">
                                    <span style="font-size: 16px">{{ $t('Expert mode') }}</span>
                                </b-switch>
                            </div>
                        </div>
                    </div>

                    <div v-if="!isAnyImportRunning">
                        <div class="columns">
                            <div class="column is-half">
                                <ul class="is-list is-list--disc">
                                    <li>{{ $t('You can import either all or just the selected languages') }}</li>
                                    <li>{{ $t('You can also choose to import either all data or just the updated articles') }}</li>
                                </ul>

                                <b-field :addons="false" :label="$t('Languages import')">
                                    <div class="control is-clearfix">
                                        <b-radio v-model="languagesSelection"
                                                 name="languagesSelection"
                                                 native-value="">
                                            {{ $t('All languages') }}
                                        </b-radio>
                                    </div>
                                    <div class="control is-clearfix">
                                        <b-radio v-model="languagesSelection"
                                                 name="languagesSelection"
                                                 native-value="selection">
                                            {{ $t('Language selection') }}
                                        </b-radio>
                                    </div>
                                    <div class="control is-clearfix" v-if="languagesSelection === 'selection'">
                                        <b-checkbox v-model="languages"
                                                    :native-value="availableLanguage.locale"
                                                    v-for="(availableLanguage, key) in availableLanguages"
                                                    :key="key">
                                            {{ availableLanguage.locale }}&nbsp;&nbsp;
                                        </b-checkbox>
                                    </div>
                                </b-field>
                                <b-field :addons="false" :label="$t('Additional options')">
<!--                                    <div v-if="expertMode" class="control is-clearfix">-->
<!--                                        <b-checkbox native-value="1"-->
<!--                                                    v-model="importOptions.sap"-->
<!--                                                    :aria-checked="false">-->
<!--                                            {{ $t('Update SAP data') }}-->
<!--                                        </b-checkbox>-->
<!--                                    </div>-->
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox native-value="1"
                                                    v-model="importOptions.publish"
                                                    :aria-checked="false">
                                            {{ $t('Publish directly to the website') }}
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox native-value="1"
                                                    v-model="importOptions.products"
                                                    :aria-checked="false">
                                            {{ $t('Update product page structure') }}
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox native-value="1"
                                                    v-model="importOptions.sqlite"
                                                    :aria-checked="false">
                                            {{ $t('Clear product caches') }}
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox native-value="1"
                                                    v-model="importOptions.reSqlite"
                                                    :aria-checked="false">
                                            {{ $t('Rebuild product caches') }}
                                        </b-checkbox>
                                    </div>
                                    <div class="control is-clearfix">
                                        <b-checkbox :true-value="userEmail"
                                                    v-model="importOptions.email"
                                                    :aria-checked="false">
                                            {{ $t('Notify me via email when completed') }} ({{ userEmail }})
                                        </b-checkbox>
                                    </div>
                                </b-field>
                            </div>
                            <div class="column is-half">
                                <div v-if="expertMode">
                                    <template v-for="(availableLanguage, key) in availableLanguages">
                                        <div v-if="availableLanguage.last_mt_import_started_t > availableLanguage.last_mt_import_finished_t" :key="key">
                                            <strong>{{ availableLanguage.locale }} - </strong> <span style="color:#9ED300;">{{ $t('running since') }}</span> {{ availableLanguage.last_mt_import_started | date }} ({{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }})
                                        </div>
                                        <div v-else :key="key">
                                            <strong>{{ availableLanguage.locale }} - </strong> {{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }} ({{ $t('Duration') }}: {{ getDuration(availableLanguage.last_mt_import_started, availableLanguage.last_mt_import_finished) }})
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <button @click="importData" class="button is-primary">
                            <span>{{ $t('Start') }}</span>
                        </button>
                    </div>
                    <div v-else>
                        <p>{{ $t('One or more imports are running right now. You cannot start another one.') }}</p>
                        <template v-for="(availableLanguage, key) in availableLanguages">
                            <div v-if="availableLanguage.last_mt_import_started_t > availableLanguage.last_mt_import_finished_t" :key="key">
                                <strong>{{ availableLanguage.locale }} - </strong> <span style="color:#9ED300;">{{ $t('running since') }}</span> {{ availableLanguage.last_mt_import_started | date }} ({{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }})
                            </div>
                            <div v-else :key="key">
                                <strong>{{ availableLanguage.locale }} - </strong> {{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }} ({{ $t('Duration') }}: {{ getDuration(availableLanguage.last_mt_import_started, availableLanguage.last_mt_import_finished) }})
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
